import { RedirectRequest } from "@azure/msal-browser"
// import { GetProfile } from "../Utils/ReactQueries"
import React from "react"

let clientId = ""
// console.log(process.env.GATSBY_APP_AAD_APPLICATION_ID)
if (
  process.env.GATSBY_APP_AAD_APPLICATION_ID !== undefined &&
  process.env.GATSBY_APP_AAD_APPLICATION_ID !== null
)
  clientId = process.env.GATSBY_APP_AAD_APPLICATION_ID

/**
 * Config object to be passed to Msal on creation
 */
export const msalConfig = {
  auth: {
    clientId: process.env.GATSBY_APP_AAD_APPLICATION_ID,
    authority:
      process.env.GATSBY_APP_AAD_B2C_INSTANCE +
      process.env.GATSBY_APP_AAD_B2C_TENTANT +
      "/" +
      process.env.GATSBY_APP_AAD_B2C_SIGNINGPOLICY,
    validateAuthority: false,
    knownAuthorities: [process.env.GATSBY_APP_AAD_B2C_INSTANCE],
    redirectUri: process.env.GATSBY_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.GATSBY_APP_POST_REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
}

let b2cScopes = ""

if (
  process.env.GATSBY_APP_AAD_B2C_SCOPES_ACCESS_USER_DATA !== undefined &&
  process.env.GATSBY_APP_AAD_B2C_SCOPES_ACCESS_USER_DATA !== null
)
  b2cScopes = process.env.GATSBY_APP_AAD_B2C_SCOPES_ACCESS_USER_DATA

export const loginRequest: RedirectRequest = {
  scopes: [b2cScopes],
}
